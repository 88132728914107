<template>
  <div class="records-container">
     <div class="nav-bar">
       <van-nav-bar>
        <template #left>
          <my-icon fontSize="28" iconName="icon-jiantou_zuo_48" @click="backHandle" />
        </template>
        <template #title>
          <div class="tab-button">
              <span :class="tabActive === 0 ? 'active' : ''" @click="onClickTabHandle(0)">{{$t('login.bookMeet')}}</span>
              <span :class="tabActive === 1 ? 'active' : ''" @click="onClickTabHandle(1)">{{$t('seeting.oldMeet')}}</span>
          </div>
        </template>
        <template #right>
          <my-icon fontSize="54" iconName="icon-xinzeng_30_lv" @click="plusHandle"/>
        </template>
       </van-nav-bar>
     </div>
     
     <!-- 会议记录列表 -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-if="Object.keys(groupRecords).length > 0"
          :finished-text="$t('seeting.noMore')"
            class="records-list"
            v-model="isLoading"
            :finished="loadFinished"
            :error.sync="loadError"
            :error-text="$t('seeting.clickRelod')"
            @load="loadMeetingList">
          <div class="item-card" v-for="(list, date) in groupRecords" :key="date">
              <div class="date" v-if="tabActive === 0">{{ date | formatTimeFilter('YYYY年MM月DD日', $t('seeting.today')) }}</div>
              <div class="date history-date" v-if="tabActive === 1">
                <div>{{ date | formatTimeFilter('MM月DD日 ddd') }}</div>
                <div>{{ date | formatTimeFilter('YYYY年') }}</div>
              </div>
              <div class="records" :class="{ 'first': idx === 0 }" v-for="(item, idx) in list" :key="idx">
                  <div class="line" v-if="idx !== 0"></div>
                  <div class="title">{{ item.title }}</div>
                  <div class="time">
                    {{ item.beginTime | formatTimeFilter('HH:mm') }} - {{ item.endTime | formatTimeFilter('HH:mm') }}
                    <span v-if="tabActive === 0">{{ getMeetingStateLabel(item) }}</span>
                    <span v-if="tabActive === 1" style="color: #999999">{{ $t('seeting.firstPerson') + item.ownerName }}</span>
                  </div>
                  <div class="conference-no">{{$t('login.meetNumber')}}{{ item.conferenceNo | formatCodeFilter }}</div>
                  <my-icon fontSize="28" iconName="icon-jiantou_xiajiantou_32_hui1" class="icon-arrow" @click="gotoDetail(item)" />
              </div>
          </div>
      </van-list>
    </van-pull-refresh>

    <div v-if="!isLoading && loadFinished && Object.keys(groupRecords).length === 0" class="records-empty">
        <img src="@/assets/images/list_empty.png"/>
        <div class="desc">{{$t('seeting.no')}}{{ tabActive ? $t('seeting.ls') : $t('seeting.yy')}}{{$t('seeting.meet')}}</div>
    </div>

    <div class="records-loading" v-if="isLoading && dataRecords.length === 0">
      <van-loading size="24px" 
        text-color="#000000" 
        color="#1DAF69">{{$t('seeting.loading')}}</van-loading>
    </div>
  </div>
</template>

<script>
import '@/utils/filter'
import moment from 'moment'
import { groupBy } from 'lodash'
import {  getAcceptLanguage } from "@/utils/auth";
import { LANG_ZH } from '@/constant/index'

export default {
  props: {
    tab: { default: 0 }
  },

  mounted () {
  },

  watch: {
    tab: {
      handler(value) {
        console.error('tab',value)
        this.onClickTabHandle(value)
      },
      immediate: true
    } 
  },

  data() {
    return {
      tabActive: 0,
      isLoading: false,
      loadFinished: false,
      loadError: false,
      dataRecords: [],
      pageIndex: 1,
      refreshing: false,
    }
  },
  computed: {
    groupRecords() {
       const records = this.dataRecords
       .map(
         data => {
           const date = moment(this.tabActive === 1 ? data.enterTime : data.beginTime).format('YYYY-MM-DD')
           return {
             date,
             ...data
           }
         }
       )
       return groupBy(records, data => { return data.date })
    },


  },

  methods: {
    onClickTabHandle(idx) {
      this.tabActive = idx
      this.pageIndex = 1
      this.dataRecords = []
      this.loadMeetingList()
    },
    backHandle() {
      this.$emit('backHandle')
    },
    plusHandle() {
      const meetingType = this.tabActive
      this.$router.push({ name: 'appointment', query: { meetingType } })
    },

    gotoDetail(item) {
      const id = this.tabActive ? item.conferenceId : item.id
      const meetingType = this.tabActive
      const rtcRoomNo = item.rtcRoomNo
      
      const exitTime = item.exitTime
      const enterTime = item.enterTime
      const conferenceNo = item.conferenceNo
      this.$router.push({ name: 'detail', query: { meetingType, id, rtcRoomNo, exitTime, enterTime, conferenceNo } })
    },

    //历史会议列表 预约会议列表 今日会议列表
    async loadMeetingList() {
      let data = {
        pageNum: this.pageIndex,
        pageSize: 10,
        param: {},
      }

      //---------------- SDK 历史会议列表 预约会议列表 -------------------------
      let fetchPromise = () => {}
      this.isLoading = true
      if (this.tabActive === 0) {
        fetchPromise = sdk.meetingManagement.getScheduleMeetingList(data)
      }
      if (this.tabActive === 1) {
        fetchPromise = sdk.meetingManagement.getHistoryMeetingList(data)
      }

      fetchPromise.then(res => {
        const { total, records } = res
        this.loadError = false

        if (this.refreshing) {
          this.dataRecords = []
          this.refreshing = false
        }

        this.isLoading = false
        if (total === 0) {
          this.dataRecords = []
        }
        if (total > 0 && records.length > 0) {
           let dataArray = this.pageIndex === 1 ? [] : this.dataRecords
           this.dataRecords = dataArray.concat(records)

           this.loadFinished = false
           this.pageIndex++
        } else {
           this.loadFinished = true
        }  
      }).catch(error => {
        console.error(error)
        this.isLoading = false
        this.loadError = true
        this.$eventBus.$emit('request-error', error)
      })
    },

    onRefresh() {
      // 清空列表数据
      this.pageIndex = 1
      this.dataRecords = []
      this.loadError = false
      this.loadFinished = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.isLoading = true
      this.loadMeetingList()
    },

     //处理预约状态显示文本
    getMeetingStateLabel(item) {
      let text = "";
      const minutes = moment(item.beginTime).diff(moment(),'minutes')

      //判断是否同一天
      const isSameDay = moment(item.beginTime).isSame(moment().format('YYYY-MM-DD'), 'day')
      

      if (item.status === 0 || item.status === 20 || item.status === 40) {

        //当前时间已经超过或等于会议开始时间 显示 "立即"
        if (minutes <= 0) { 
          text = this.$t('seeting.immediately');
        } 

        //当前时间距离会议开始时间超过10分钟且在同一天 显示 “即将召开”
        if (minutes > 10 && isSameDay) {
          text =  this.$t('seeting.imMeet'); 
        } 
        
        //当前时间距离会议开始时间10分钟以内 显示 “XX分钟开始”
        if (minutes > 0 && minutes <= 10) {
          const langVal = getAcceptLanguage()
          text =  langVal === LANG_ZH ? `${minutes}` + this.$t('seeting.afterStart') : `in ${minutes} minutes`;
        }
      } else if (item.status === 10) {
        text = this.$t('seeting.haveHand');
      } else if (item.status === 30) {
        text = this.$t('seeting.Cancelled');
      }
      return text
    }


  }

}
</script>

<style scoped lang="less">
.flex_center() { 
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text_overflow() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.records-container {
   width: 100%;

   .tab-button {
     width: 410px;
     height: 54px;
     .flex_center();
     span {
       display: inline-block;
       font-size: 28px;
       width: 202px;
       height: 50px;
       font-weight: 400;
       color: #1AB370;
       text-align: center;
       line-height: 50px;
       border: 2px solid #1AB370;
     }
     span.active {
       background: #1AB370;
       color: #FFFFFF;
     }
     span:nth-child(1) {
       border-radius: 10px 0 0 10px;
     }
     span:nth-child(2) {
       border-radius: 0 10px 10px 0;
       border-left: none;
     }
   }

   .nav-bar {
     height: 120px;
     width: 100%;
     position: fixed;
     top: 0;
     left: 0;
     z-index: 1;
   }

   .records-list {
      padding-top: 120px;
      padding-bottom: 143px;
      box-sizing: border-box;
      background: #FAFAFA;
     .item-card {
       background: #fff;
       padding: 30px 16px 34px 30px;
       box-sizing: border-box;
       border-radius: 8px;
       width: 94.67%;
       margin:0 auto;
       margin-top: 16px;
       .date {
          font-size: 36px;
          font-weight: 600;
          color: #333333;
       }
       .history-date {
         .flex_center();
       }
       .line {
         width: 97.67%;
         height: 1px;
         background: #DFDFDF;
         margin-top: 34px;
       }
       .title {
          font-size: 32px;
          font-weight: 500;
          color: #333333;
          margin-top: 30px;
          max-width: 600px;
          .text_overflow()
       }
       .time {
          font-size: 26px;
          font-weight: 400;
          color: #999999;
          margin-top: 28px;
          span {
            display: inline-block;
            vertical-align: bottom;
            margin-left: 26px;
            color: #FF8200;
            max-width: 390px;
            .text_overflow()
          }
       }
       .conference-no {
          margin-top: 28px;
          font-size: 26px;
          font-weight: 400;
          color: #999999;

       }
       .icon-arrow {
         position: absolute;
         top: 52px;
         right: 0;
       }
       .records {
         position: relative;
       }
       .records.first .title{
          margin-top: 40px;
       }
     }
   }

   .records-empty {
     text-align: center;
     img {
       width: 220px;
       height: 220px;
       margin:0 auto;
       margin-top: 396px;
     }
     .desc {    
        font-size: 32px;
        font-weight: 400;
        color: #999999;
     }
   }

   .records-loading {
     width: 280px;
     height: 88px;
     line-height: 88px;
     background: #FFFFFF;
     box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
     border-radius: 44px;
     border: 1px solid rgba(0, 0, 0, 0.1);
     text-align: center;
     position: absolute;
     top: 144px;
     left: calc(50% - 140px);
   }
}

::v-deep .nav-bar .van-nav-bar__content {
    width: 100%;
    height: 120px;
}
</style>
