<template>
  <div class="home-container">
    <!-- 主页四宫格 -->
     <transition name="slide-right">
      <div class="home-box" v-if="active === 0">
        <van-grid :border="false" :column-num="2">
          <van-grid-item v-for="(action, idx) in gridIconList" :key="idx" :to="action.to" @click="onClickGridHandle(action, idx)">
            <template #icon>
              <my-icon fontSize="183" :iconName="action.active ? action.activeIcon : action.defaultIcon"></my-icon>
            </template>
            <template #text>
              <span class="grid-text">{{ action.desc }}</span>
            </template>
          </van-grid-item>
         </van-grid>
       </div>
     </transition>

   <!-- 会议记录列表 -->
   <transition name="slide-right">
      <meeting-records v-if="active === 1" :tab="tabActive" @backHandle="backHandle"></meeting-records>
   </transition>

   <!-- 底部tab条 -->
   <van-tabbar v-model="active" class="tab-bar">
      <van-tabbar-item replace v-for="(action, idx) in tabbarIconList" :key="idx">
          <span class="tab-text">{{ action.desc }}</span>
          <template #icon="props">
            <my-icon fontSize="48" :iconName="props.active ? action.activeIcon : action.defaultIcon"></my-icon>
          </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import MeetingRecords from "./components/records"
import { setLocalUserInfo, setAvatar,getToken } from "@/utils/auth"

export default {
   data() {
     return {
       active: 0,
       tabActive: 0,
       gridIconList: [
          {
            defaultIcon: 'icon-new_meeting',
            activeIcon: 'icon-xinhuiyibeifen_jingguo',
            desc: '新会议',
            to:'/fast'
          },
          {
            defaultIcon: 'icon-jiaruhuiyi',
            activeIcon: 'icon-jiaruhuiyi_jingguo',
            desc: '加入会议',
            to:'/join'
          },
          {
            defaultIcon: 'icon-yuyuehuiyi',
            activeIcon: 'icon-yuyuehuiyibeifen_jingguo',
            desc: '预约会议',
            to:'/appointment'
          },
          {
            defaultIcon: 'icon-meeting_set',
            activeIcon: 'icon-meeting_set_active',
            desc: '会议设置',
            to:'./meetSetting'
          }
       ], 
       tabbarIconList: [
          {
            defaultIcon: 'icon-home_48_black',
            activeIcon: 'icon-home_48_green',
            desc: '首页'
          },
          {
            defaultIcon: 'icon-list_48_black',
            activeIcon: 'icon-list_48_green',
            desc: '会议'
          }
       ]
     }
   },

   components: {
     MeetingRecords
   },
   mounted() {
     console.log(getToken(),'面板页面测试退出登录后token是否存在')
      //初始化用户信息
      sdk.meetSetting.getUserInfo().then((res) => {
        this.$store.dispatch("user/getInfo", {
          res
        })
        // 存储头像
        setAvatar(res.avatar)

        //防止页面刷新数据丢失 存一份本地缓存
        setLocalUserInfo(res)
      }).catch(error => {
        this.$eventBus.$emit('request-error', error)
      })

      //路由参数处理
      const active = this.$route.query?.active
      if (active) {
        this.active = Number(active)
      }
      const tab = this.$route.query?.tab
      if (tab) {
        this.tabActive = Number(tab)
      }
   },

   methods: {
     onClickGridHandle(action, idx) {
       this.gridIconList
       .map(
        icon => icon.active = false
       )
       action.active = true
       this.$set(this.gridIconList, idx, action)
      //  action.clickEvent && action.clickEvent()
     },
     backHandle() {
       this.active = 0
       this.tabActive = 0
     }
   }
}
</script>

<style scoped lang="less">
.home-container {
   position: relative;
   height: 100vh;
   background: #FAFAFA;
   .home-box {
     width: 100%;
     height: 100%;
     background: #FFF;
   }
   .van-grid {
     width: 80%;
     margin: 0 auto;
     padding-top: 140px;
   }
   .tab-bar {
     height: 108px;
   }
   .tab-text {
     font-size: 20px;
   }
   .grid-text {
     font-size: 28px;
     font-weight: 400;
     color: #333333;
     margin-top: 20px;
   }
}

::v-deep .van-tabbar-item--active {
   color: #1DAF69;
}

::v-deep .van-tabbar-item__icon {
  margin-bottom: 0;
}

.slide-right-enter-active,
	.slide-right-leave-active,
	.slide-left-enter-active,
	.slide-left-leave-active {
		will-change: transform;
		transition: all 500ms;
		position: absolute; /**这里的定位使用relative可以解决切换时页面残影**/
	}
	.slide-right-enter {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}
	.slide-right-leave-active {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}
	.slide-left-enter {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}
</style>